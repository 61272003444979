import React, { Component, Fragment } from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import classnames from 'classnames';
import moment from 'moment';
import cronstrue from 'cronstrue';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import { PIPELINES_ROUTES } from 'modules/transformationPipelines/constants';
import { AUTOMATION_ID, LOAD_STATUS, PERMISSIONS } from 'modules/common/constants';
import ErrorPage from 'modules/common/components/ErrorPage';
import SkeletonItem from 'modules/common/components/SkeletonItem';
import Switch from 'modules/common/components/Switch';
import Breadcrumbs, { BreadcrumbItemType } from 'modules/common/components/Breadcrumbs';
import TextWithTooltip from 'modules/common/components/TextWithTooltip';
import ButtonWithTooltip from 'modules/common/components/ButtonWithTooltip';
import Accordion from 'modules/common/components/Accordion';
import { formatString } from 'modules/common/utils';
import { Client, LoadStatus, Owner, RouteComponentProps } from 'modules/common/interfaces';
import { transformationApiDefinitions } from 'modules/service/types';
import { locale } from 'modules/i18n';
import { FEEDS_ROUTES } from 'modules/feeds/constants';
import {
    INSTANCES_ROUTES,
    INSTANCE_STATE,
    INSTANCE_DETAILS_OPERATION_IN_PROGRESS_REPORTER,
    INSTANCE_RUN_MODE
} from '../../constants';
import InstanceAdHocRunDialog from '../InstanceAdHocRunDialog';
import InstanceDeleteDialog from '../InstanceDeleteDialog';

import local from './local.module.scss';

interface InstanceDetailsLayoutProps {
    clients: Client[];
    owners: Owner[];
    data: transformationApiDefinitions['InstanceDetailsResponseDto'];
    resetData: Function;
    loadData: Function;
    isOperationInProgress: boolean;
    operationInProgressReporterData: { reporter: string | null; prevStateData: object };
    loadStatus: LoadStatus;
    goToInstancesListPage: Function;
    intl: IntlShape;
    togglePauseInstanceSchedule: Function;
    toggleEnableInstance: Function;
    goToEditInstancePage: Function;
    openAdHocRunDialog: Function;
    setRunMode: Function;
    archiveInstance: Function;
    unarchiveInstance: Function;
    showInstanceDeleteDialog: Function;
}

interface RouteMatchParams {
    clientId: string;
    instanceId: string;
}

class InstanceDetailsLayout extends Component<InstanceDetailsLayoutProps & RouteComponentProps<RouteMatchParams>> {
    public componentDidMount() {
        const { loadData, resetData, match: { params: { clientId, instanceId } } } = this.props;
        const { canView } = this.checkAccess();

        resetData();

        if (canView) {
            loadData(clientId, instanceId);
        }
    }

    public componentDidUpdate(prevProps) {
        const { match: { params: { clientId: prevClientId, instanceId: prevInstanceId } } } = prevProps;
        const { loadData, resetData, match: { params: { clientId, instanceId } } } = this.props;

        // in case when client or instance ID in page URL is changed by user, component is not re-mounted, so need to check access again
        if ((clientId !== prevClientId) || (instanceId !== prevInstanceId)) {
            const { canView } = this.checkAccess();

            resetData();

            if (canView) {
                loadData(clientId, instanceId);
            }
        }
    }

    public render() {
        const { canView, canManage } = this.checkAccess();

        if (!canView) {
            return (
                <ErrorPage error='403' />
            );
        }

        const {
            data: { name, state, usedByOdpReportingApi },
            loadStatus,
            isOperationInProgress,
            goToEditInstancePage,
            match: { params: { clientId, instanceId } },
            archiveInstance,
            unarchiveInstance,
            showInstanceDeleteDialog
        } = this.props;

        const isLoaded = loadStatus === LOAD_STATUS.LOADED;
        const showDeleteButton = isLoaded && state !== INSTANCE_STATE.ENABLED && !usedByOdpReportingApi;

        return (
            <div>
                <InstanceAdHocRunDialog />
                <InstanceDeleteDialog />
                {
                    this.renderBreadcrumbs()
                }
                <div className={local.card}>
                    <div className={classnames(local.titleContainer, 'container-row')}>
                        <div className={local.title}>
                            {
                                isLoaded ?
                                    <Fragment>
                                        {
                                            usedByOdpReportingApi &&
                                            <div className={local.usedInReportLabel}>
                                                <FormattedMessage id='common.usedInReport' />
                                            </div>
                                        }
                                        {name}
                                    </Fragment> :
                                    <SkeletonItem width='320px' height='24px' />
                            }
                        </div>
                        {
                            canManage && isLoaded &&
                            <div className={classnames(local.buttonsContainer, 'container-row')}>
                                {
                                    state !== INSTANCE_STATE.ARCHIVED &&
                                    <div className='ls-button'>
                                        <button
                                            id={AUTOMATION_ID.INSTANCE_ARCHIVE_BTN}
                                            className='btn-negative-transparent'
                                            disabled={isOperationInProgress}
                                            onClick={() => {
                                                if (!isOperationInProgress) {
                                                    archiveInstance(instanceId);
                                                }
                                            }}>
                                            <FormattedMessage id='common.archive' />
                                        </button>
                                    </div>
                                }
                                {
                                    showDeleteButton &&
                                    <div className='ls-button'>
                                        <button
                                            id={AUTOMATION_ID.INSTANCE_DELETE_BTN}
                                            className='btn-negative-transparent'
                                            disabled={isOperationInProgress}
                                            onClick={() => {
                                                if (!isOperationInProgress) {
                                                    showInstanceDeleteDialog(clientId, instanceId);
                                                }
                                            }}>
                                            <FormattedMessage id='common.delete' />
                                        </button>
                                    </div>
                                }
                                {
                                    state === INSTANCE_STATE.ARCHIVED &&
                                    <div className='ls-button'>
                                        <button
                                            id={AUTOMATION_ID.INSTANCE_RESTORE_BTN}
                                            disabled={isOperationInProgress}
                                            onClick={() => {
                                                if (!isOperationInProgress) {
                                                    unarchiveInstance(instanceId);
                                                }
                                            }}>
                                            <FormattedMessage id='common.restore' />
                                        </button>
                                    </div>
                                }
                                {
                                    state !== INSTANCE_STATE.ARCHIVED &&
                                    <div className='ls-button'>
                                        <button
                                            id={AUTOMATION_ID.INSTANCE_EDIT_BTN}
                                            disabled={isOperationInProgress}
                                            onClick={() => {
                                                if (!isOperationInProgress) {
                                                    goToEditInstancePage(clientId, instanceId);
                                                }
                                            }}>
                                            <FormattedMessage id='common.edit' />
                                        </button>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    {
                        [ INSTANCE_STATE.ENABLED, INSTANCE_STATE.DISABLED ].includes(state!) &&
                        <div className={classnames(local.switch, 'container-row')}>
                            <FormattedMessage id='common.off' />
                            <Switch
                                disabled={!isLoaded || isOperationInProgress || !canManage}
                                checked={state === INSTANCE_STATE.ENABLED}
                                onChange={this.toggleEnableInstance}
                            />
                            <FormattedMessage id='common.on' />
                        </div>
                    }
                    {
                        isLoaded ?
                            this.renderDetails() :
                            this.renderSkeletonRows()
                    }
                </div>
            </div>
        );
    }

    private checkAccess = () => {
        const client = this.getClient();
        const owner = this.getPipelineOwner();

        const canView = client && client.permissions.includes(PERMISSIONS.INSTANCES.VIEW);
        const canRun = client && client.permissions.includes(PERMISSIONS.INSTANCES.RUN) && owner &&
            owner.permissions.includes(PERMISSIONS.PIPELINES.VIEW);
        const canManage = client && client.permissions.includes(PERMISSIONS.INSTANCES.MANAGE) && owner &&
            owner.permissions.includes(PERMISSIONS.PIPELINES.VIEW);
        const canViewFeeds = client && client.permissions.includes(PERMISSIONS.FEEDS.VIEW);

        return { canView, canRun, canManage, canViewFeeds };
    }

    private getClient = () => {
        const { clients, match: { params: { clientId } } } = this.props;

        return clients.find(({ id }) => id === clientId);
    }

    private getPipelineOwner = () => {
        const { owners, data: { pipelineOwnerId } } = this.props;

        return owners.find(({ id }) => id === pipelineOwnerId);
    }

    private renderBreadcrumbs = () => {
        const { loadStatus, goToInstancesListPage, data: { name, state } } = this.props;

        const breadcrumbsItems: BreadcrumbItemType[] = [
            {
                id: AUTOMATION_ID.ALL_INSTANCES_BREADCRUMB,
                label: (<FormattedMessage id='instances.allInstances' />),
                onClick: () => { goToInstancesListPage(); }
            },
            {
                label: (
                    <Fragment>
                        {loadStatus === LOAD_STATUS.LOADED ? name : ''}
                        {
                            state && ![ INSTANCE_STATE.ENABLED, INSTANCE_STATE.DISABLED ].includes(state) &&
                            <Fragment>
                                &nbsp;[<FormattedMessage id={`instances.state.${state}`} defaultMessage='Unknown' />]
                            </Fragment>
                        }
                    </Fragment>
                )
            }
        ];

        return (
            <Breadcrumbs
                items={breadcrumbsItems}
                selectedItemIndex={1}
            />
        );
    }

    private renderConfigurationBlock = () => {
        const {
            pipelinePurpose,
            pipelineOwnerName,
            pipelineOwnerId,
            pipelineId,
            airflowConfiguration,
            transformationInstanceExportTargets
        } = this.props.data;

        const airflowConfigurationDetails: JSX.Element[] | undefined = airflowConfiguration?.map(({ key, value, isSecret }) => (
            <div key={key} className={local.dataRow}>
                {/* TODO consider using purpose instead of ID as ID can be different on different environments */}
                <FormattedMessage id={`${pipelineId}.${key}`} defaultMessage={key} />
                : {isSecret && value ? '*'.repeat(12) : <FormattedMessage id={`${pipelineId}.${key}.option.${value}`} defaultMessage={value || ' '} />}
            </div>
        ));

        const exportTargetsDetails: JSX.Element[] = [];
        transformationInstanceExportTargets && transformationInstanceExportTargets.forEach(({ exportTargetName, configuration }, index) => {
            exportTargetsDetails.push(
                <div key={`target_${index}_name`} className={classnames(local.smallLabel, 'ellipsis')}>
                    {exportTargetName}
                </div>
            );

            configuration!.forEach(({ key, value, isSecret }) =>
                exportTargetsDetails.push(
                    <div key={`target_${index}_${key}`} className={local.dataRow}>
                        <FormattedMessage id={`${exportTargetName}.${key}`} defaultMessage={key} />
                        : {isSecret && value ? '*'.repeat(12) : <FormattedMessage id={`${exportTargetName}.${key}.option.${value}`} defaultMessage={value || ' '} />}
                    </div>
                )
            );
        });

        return (
            <Fragment>
                {
                    pipelinePurpose &&
                    <Fragment>
                        <div className={local.smallLabel}>
                            <FormattedMessage id='common.transformationPipeline' />
                        </div>
                        <div className={local.linkContainer}>
                            <a href={`#${formatString(PIPELINES_ROUTES.DETAILS, pipelineOwnerId, pipelineId)}`}>
                                {pipelinePurpose}
                            </a>
                        </div>
                        <div className={local.ownerName}>
                            {pipelineOwnerName}
                        </div>
                    </Fragment>
                }
                {
                    airflowConfiguration &&
                    <div>
                        <div className={local.bigLabel}>
                            <FormattedMessage id='instances.airflowConfiguration' />
                        </div>
                        {airflowConfigurationDetails}
                    </div>
                }
                {
                    exportTargetsDetails.length ?
                        <div>
                            <div className={local.bigLabel}>
                                <FormattedMessage id='instances.exportTargets' />
                            </div>
                            {exportTargetsDetails}
                        </div> :
                        null
                }
                {this.renderTriggersTable()}
            </Fragment>
        );
    }

    private renderTriggersTable = () => {
        const {
            transformationInstanceTriggers,
            instanceTriggerLogicalType,
            pipelineId,
            clientId
        } = this.props.data;
        const { canViewFeeds } = this.checkAccess();

        return (
            transformationInstanceTriggers?.length ?
                <div>
                    <div className={local.bigLabel}>
                        <FormattedMessage id='instances.triggers' />
                    </div>
                    <div className={local.smallLabel}>
                        <FormattedMessage id='instances.logicType' />
                    </div>
                    <div className={local.propertyValue}>
                        {instanceTriggerLogicalType}
                    </div>
                    <div className={local.triggersTableContainer}>
                        <div className={classnames(local.row, local.header, 'container-row')}>
                            <div className={local.columnType}>
                                <FormattedMessage id='common.type' />
                            </div>
                            <div className={local.columnName}>
                                <FormattedMessage id='common.name' />
                            </div>
                        </div>
                        {
                            transformationInstanceTriggers.map(({ name, upstreamInstanceId, upstreamFeedId, airflowConfigurationOverrides }, idx) => {
                                let triggerName: JSX.Element;
                                if (canViewFeeds || upstreamInstanceId) {
                                    const url = upstreamInstanceId ?
                                        `#${formatString(INSTANCES_ROUTES.DETAILS, clientId, upstreamInstanceId)}` :
                                        `#${formatString(FEEDS_ROUTES.DETAILS, clientId, upstreamFeedId)}`;

                                    triggerName = <TextWithTooltip><a href={url}>{name}</a></TextWithTooltip>;
                                } else {
                                    triggerName = <TextWithTooltip>{name}</TextWithTooltip>;
                                }

                                return (Boolean(airflowConfigurationOverrides?.length) ?
                                    <Accordion
                                        key={idx}
                                        label={
                                            <div key={idx} className={classnames(local.row, local.expandable, 'container-row')}>
                                                <div className={local.columnType}>
                                                    <FormattedMessage id={upstreamInstanceId ? 'instances.upstreamInstance' : 'instances.upstreamFeed'} />
                                                </div>
                                                <div className={classnames(local.columnName, local.linkContainer)}>
                                                    {triggerName}
                                                </div>
                                            </div>
                                        }
                                        classes={{
                                            summaryRoot: local.triggerSummary,
                                            detailsRoot: local.triggerDetails
                                        }}
                                    >
                                        <Fragment>
                                            <div className={local.triggerLabel}>
                                                <FormattedMessage id='common.configurationOverrides' />
                                            </div>
                                            {
                                                airflowConfigurationOverrides!.map(({ key, value, isSecret }) => (
                                                    <div key={key} className={local.dataRow}>
                                                        {/* TODO consider using purpose instead of ID as ID can be different on different environments */}
                                                        <FormattedMessage id={`${pipelineId}.${key}`} defaultMessage={key} />
                                                        : {isSecret && value ? '*'.repeat(12) : <FormattedMessage id={`${pipelineId}.${key}.option.${value}`} defaultMessage={value || ' '} />}
                                                    </div>
                                                ))
                                            }
                                        </Fragment>
                                    </Accordion> :
                                    <div key={idx} className={classnames(local.row, 'container-row')}>
                                        <div className={local.columnType}>
                                            <FormattedMessage id={upstreamInstanceId ? 'instances.upstreamInstance' : 'instances.upstreamFeed'} />
                                        </div>
                                        <div className={classnames(local.columnName, local.linkContainer)}>
                                            {triggerName}
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div> :
                null
        );
    }

    private renderDetails = () => {
        const {
            data: {
                description,
                cronExpression,
                lastRunTimestamp,
                nextRunTimestamp,
                updatedBy,
                updateTimestamp = 0,
                isScheduleActive,
                producesFullDataset
            },
            intl
        } = this.props;

        let parsedCronExpression;
        try {
            parsedCronExpression = cronstrue.toString(cronExpression || '', { locale, use24HourTimeFormat: true, verbose: true });
        } catch (error) {
            parsedCronExpression = <FormattedMessage id='common.invalidCronExpression' />;
        }

        return (
            <Fragment>
                {
                    this.renderInstanceStateLabel()
                }
                {
                    description &&
                    <Fragment>
                        <div className={local.smallLabel}>
                            <FormattedMessage id='common.description' />
                        </div>
                        <div className={local.propertyValue}>
                            {description}
                        </div>
                    </Fragment>
                }
                {
                    this.renderConfigurationBlock()
                }
                {
                    Boolean(cronExpression) &&
                    <Fragment>
                        <div className={local.bigLabel}>
                            <FormattedMessage id='common.schedule' />
                        </div>
                        <div className={local.dataRow}>
                            {parsedCronExpression}
                        </div>
                        {
                            nextRunTimestamp && (
                                <div className={local.nextRunTimeBlock}>
                                    <div className={local.nextRun}>
                                        {formatString(intl.formatMessage({ id: 'common.nextRunOn' }), moment.utc(nextRunTimestamp).format('DD MMMM YYYY, HH:mm UTC'))}
                                    </div>
                                    {
                                        !isScheduleActive &&
                                        <div className={local.pausedLabel}>
                                            <FormattedMessage id='common.paused' />
                                        </div>
                                    }
                                </div>
                            )
                        }
                    </Fragment>
                }
                {this.renderScheduleControls()}
                {
                    lastRunTimestamp &&
                    <div className={local.dataRow}>
                        {formatString(intl.formatMessage({ id: 'common.lastRunOn' }), moment.utc(lastRunTimestamp).format('DD MMMM YYYY, HH:mm UTC'))}
                    </div>
                }
                <div className={local.smallLabel}>
                    <FormattedMessage id='instances.fullDataset' />
                    <FontAwesomeIcon icon={producesFullDataset ? faCheck : faTimes} className={local.fullDatasetIcon} />
                </div>
                {
                    this.renderIngestionSettings()
                }
                <div className={local.divider} />
                <div className={local.lastUpdatedTitle}>
                    <FormattedMessage id='common.lastUpdated' />
                </div>
                <div className={local.lastUpdatedValue}>
                    {`${moment.utc(updateTimestamp).format('DD MMMM YYYY [at] HH:mm UTC')} by ${updatedBy}`}
                </div>
            </Fragment>
        );
    }

    private renderIngestionSettings = () => {
        const { ingestionSettings } = this.props.data;

        if (!ingestionSettings) {
            return null;
        }

        const {
            primaryKeyColumns,
            timePartitioningColumn,
            clusterColumns,
            overwriteByColumns,
            ingestMode
        } = ingestionSettings || {};

        return (
            <Fragment>
                {
                    Boolean(primaryKeyColumns?.length) &&
                    <div>
                        <div className={local.smallLabel}>
                            <FormattedMessage id='common.primaryKeyColumns' />
                        </div>
                        {
                            primaryKeyColumns?.map((column, index) => (
                                <div key={index} className={classnames(local.dataRow, 'ellipsis')}>
                                    {column}
                                </div>
                            ))
                        }
                    </div>
                }
                {
                    timePartitioningColumn &&
                    <div>
                        <div className={local.smallLabel}>
                            <FormattedMessage id='common.partitionColumn' />
                        </div>
                        <div className={classnames(local.propertyValue, 'ellipsis')}>
                            {timePartitioningColumn}
                        </div>
                    </div>
                }
                {
                    Boolean(clusterColumns?.length) &&
                    <div>
                        <div className={local.smallLabel}>
                            <FormattedMessage id='common.clusterColumns' />
                        </div>
                        {
                            clusterColumns?.map((column, index) => (
                                <div key={index} className={classnames(local.dataRow, 'ellipsis')}>
                                    {column}
                                </div>
                            ))
                        }
                    </div>
                }
                {
                    ingestMode &&
                    <div>
                        <div className={local.smallLabel}>
                            <FormattedMessage id='common.ingestMode' />
                        </div>
                        <div className={local.propertyValue}>
                            <FormattedMessage id={`common.ingestMode.${ingestMode}`} defaultMessage={ingestMode} />
                        </div>
                    </div>
                }
                {
                    Boolean(overwriteByColumns?.length) &&
                    <div>
                        <div className={local.smallLabel}>
                            <FormattedMessage id='common.overwriteByColumns' />
                        </div>
                        {
                            overwriteByColumns?.map((column, index) => (
                                <div key={index} className={classnames(local.dataRow, 'ellipsis')}>
                                    {column}
                                </div>
                            ))
                        }
                    </div>
                }
            </Fragment>
        );
    }

    private renderSkeletonRows = () => {
        const blocksCount = 3;
        const rowsCount = 2;

        return Array.apply(null, Array(blocksCount)).map((el1, idx1) => (
            <div key={idx1} className={local.skeletonBlock}>
                <div className={local.skeletonItem}>
                    <SkeletonItem width='160px' height='24px' />
                </div>
                {
                    Array.apply(null, Array(rowsCount)).map((el2, idx2) => (
                        <div key={`${idx1}.${idx2}`} className={local.skeletonItem}>
                            <SkeletonItem width='320px' height='16px' />
                        </div>
                    ))
                }
            </div>
        ));
    }

    private getLabelClassName = (state) => {
        let className = '';

        switch (state) {
            case INSTANCE_STATE.DRAFT:
                className = local.draft;
                break;
            case INSTANCE_STATE.ARCHIVED:
                className = local.archived;
                break;
        }

        return className;
    }

    private renderInstanceStateLabel = () => {
        const { state } = this.props.data;

        return ![ INSTANCE_STATE.ENABLED, INSTANCE_STATE.DISABLED ].includes(state!) ?
            <div className={classnames(local.label, this.getLabelClassName(state))}>
                <FormattedMessage id={`instances.state.${state}`} />
            </div> :
            null;
    }

    private renderScheduleControls = () => {
        const {
            data: { state, isScheduleActive, cronExpression },
            openAdHocRunDialog,
            setRunMode,
            isOperationInProgress
        } = this.props;

        const { canRun, canManage } = this.checkAccess();
        const adHocRunCondition = [ INSTANCE_STATE.ENABLED, INSTANCE_STATE.DRAFT ].includes(state!);
        const toggleScheduleCondition = state === INSTANCE_STATE.ENABLED;

        return (
            canRun ?
                <div className={classnames(local.scheduleControlsBlock, 'container-row')}>
                    {
                        Boolean(cronExpression) &&
                        <ButtonWithTooltip
                            id={AUTOMATION_ID.INSTANCE_SCHEDULE_TOGGLE_BTN}
                            buttonText={<FormattedMessage id={this.getTogglePauseSchedulerButtonLabel(isScheduleActive)} />}
                            tooltipText={<FormattedMessage id='instances.togglePauseIncorrectState' />}
                            tooltipCondition={!toggleScheduleCondition}
                            disabled={!toggleScheduleCondition || isOperationInProgress || !canManage}
                            className='btn-transparent'
                            onClick={this.togglePauseSchedule}
                        />
                    }
                    {/* TODO uncomment commented lines when run in test mode functionality will be fixed */}
                    {/*<ButtonWithTooltip*/}
                    {/*    id={AUTOMATION_ID.INSTANCE_RUN_TEST_MODE_BTN}*/}
                    {/*    buttonText={*/}
                    {/*        <Fragment>*/}
                    {/*            <FontAwesomeIcon icon={faPlay} className={local.runIcon} />*/}
                    {/*            <FormattedMessage id='common.runNowInTestMode' />*/}
                    {/*        </Fragment>*/}
                    {/*    }*/}
                    {/*    tooltipText={<FormattedMessage id='instances.runNowInTestModeIncorrectState' />}*/}
                    {/*    tooltipCondition={!adHocRunCondition}*/}
                    {/*    disabled={!adHocRunCondition || isOperationInProgress}*/}
                    {/*    className='btn-transparent'*/}
                    {/*    onClick={() => {*/}
                    {/*        setRunMode(INSTANCE_RUN_MODE.TEST);*/}
                    {/*        openAdHocRunDialog();*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {
                        state !== INSTANCE_STATE.DRAFT &&
                        <ButtonWithTooltip
                            id={AUTOMATION_ID.INSTANCE_RUN_BTN}
                            buttonText={
                                <Fragment>
                                    <FontAwesomeIcon icon={faPlay} className={local.runIcon} />
                                    <FormattedMessage id='common.runNow' />
                                </Fragment>
                            }
                            tooltipText={<FormattedMessage id='instances.runNowIncorrectState' />}
                            tooltipCondition={!adHocRunCondition}
                            disabled={!adHocRunCondition || isOperationInProgress}
                            className='btn-transparent'
                            onClick={() => {
                                setRunMode(INSTANCE_RUN_MODE.NORMAL);
                                openAdHocRunDialog();
                            }}
                        />
                    }
                </div> :
                null
        );
    }

    private getTogglePauseSchedulerButtonLabel = (isScheduleActive) => {
        const { isOperationInProgress, operationInProgressReporterData: { reporter, prevStateData = {} } } = this.props;

        if (isOperationInProgress && reporter === INSTANCE_DETAILS_OPERATION_IN_PROGRESS_REPORTER.TOGGLE_PAUSE) {
            return (prevStateData as any).isScheduleActive ? 'common.pausing' : 'common.restarting';
        }

        return isScheduleActive ? 'common.pauseSchedule' : 'common.restartSchedule';
    }

    private togglePauseSchedule = () => {
        const {
            togglePauseInstanceSchedule,
            data: { isScheduleActive },
            match: { params: { clientId, instanceId } }
        } = this.props;

        togglePauseInstanceSchedule(clientId, instanceId, isScheduleActive);
    }

    private toggleEnableInstance = () => {
        const {
            toggleEnableInstance,
            data: { state },
            match: { params: { clientId, instanceId } }
        } = this.props;

        toggleEnableInstance(clientId, instanceId, state);
    }
}

export default InstanceDetailsLayout;
