import { formatString, buildQueryString } from 'modules/common/utils';
import * as constants from './constants';
import * as service from './service';

export const getUserProfile = () => service.get(constants.USER_PROFILE);

export const getCollectorCredentialsSearchList = (payload) => service.post(constants.COLLECTOR_CREDENTIALS_SEARCH, payload);

export const getCollectorCredentialsList = () => service.get(constants.COLLECTOR_CREDENTIALS_LIST);

export const getFeedsList = (clientId, payload) => service.post(formatString(constants.FEEDS_SEARCH, clientId), payload);

export const getCollectorsList = () => service.get(constants.COLLECTOR_SEARCH);

export const getCollectorCredentialManifest = (id) => service.get(formatString(constants.COLLECTOR_CREDENTIAL_MANIFEST, id));

export const addCollectorCredential = (ownerId, payload) => service.post(formatString(constants.ADD_COLLECTOR_CREDENTIAL, ownerId), payload);

export const getCollectorCredentialDetails = (ownerId, credentialId) => service.get(formatString(constants.COLLECTOR_CREDENTIAL, ownerId, credentialId));

export const editCollectorCredential = (ownerId, credentialId, payload) => service.put(formatString(constants.COLLECTOR_CREDENTIAL, ownerId, credentialId), payload);

export const getSourcesSearchList = (payload) => service.post(constants.SOURCES_SEARCH, payload);

export const getSourcesList = (onlyValid = false) => service.get(formatString(constants.SOURCES_LIST, onlyValid));

export const getCurrentSourceVersionDetails = (ownerId, sourceId, excludingValid = false) => service.get(formatString(constants.CURRENT_SOURCE_VERSION_DETAILS, ownerId, sourceId, excludingValid));

export const getSourceVersionDetails = (ownerId, sourceId, versionId) => service.get(formatString(constants.SOURCE_VERSION, ownerId, sourceId, versionId));

export const getCollectorConfigurationManifest = (id) => service.get(formatString(constants.COLLECTOR_CONFIGURATION_MANIFEST, id));

export const addSource = (ownerId, queryParameters, payload) => service.post(formatString(constants.ADD_SOURCE, ownerId) + buildQueryString(queryParameters), payload);

export const editSourceVersion = (ownerId, sourceId, queryParameters, payload) => service.post(formatString(constants.SOURCE, ownerId, sourceId) + buildQueryString(queryParameters), payload);

export const deleteSourceVersion = (ownerId, sourceId, versionId) => service.del(formatString(constants.SOURCE_VERSION, ownerId, sourceId, versionId));

export const getCurrentFeedVersionDetails = (clientId, feedId, excludingValid = false) => service.get(formatString(constants.CURRENT_FEED_VERSION_DETAILS, clientId, feedId, excludingValid));

export const getCollectionOffsetTemplateById = (id) => service.get(formatString(constants.COLLECTION_OFFSET_TEMPLATE_BY_ID, id));

export const getCollectionOffsetTemplate = () => service.get(constants.COLLECTION_OFFSET_TEMPLATE);

export const addFeed = (clientId, payload) => service.post(formatString(constants.ADD_FEED, clientId), payload);

export const editFeed = (clientId, feedId, payload) => service.post(formatString(constants.FEED, clientId, feedId), payload);

export const deleteFeed = (clientId, feedId) => service.del(formatString(constants.FEED, clientId, feedId));

export const feedSchedulePause = (clientId, feedId) => service.post(formatString(constants.FEED_SCHEDULE_PAUSE, clientId, feedId), {});

export const feedScheduleRestart = (clientId, feedId) => service.post(formatString(constants.FEED_SCHEDULE_RESTART, clientId, feedId), {});

export const feedEnable = (clientId, feedId) => service.post(formatString(constants.FEED_ENABLE, clientId, feedId), {});

export const feedDisable = (clientId, feedId) => service.post(formatString(constants.FEED_DISABLE, clientId, feedId), {});

export const runFeed = (clientId, feedId, versionId, payload) => service.post(formatString(constants.RUN_FEED, clientId, feedId, versionId), payload);

export const getClients = () => service.get(constants.GET_MEMBER_CLIENTS);

export const addClient = (payload) => service.post(constants.CLIENT, payload);

export const addClientMembership = (memberId, clientId, roles) => service.post(formatString(constants.CLIENT_MEMBERSHIP, memberId, clientId), { roles });

export const addClientRole = (memberId, clientId, role) => service.post(formatString(constants.CLIENT_ROLE, memberId, clientId, role));

export const addClientMembershipByUsername = (clientId, payload) => service.post(formatString(constants.CLIENT_MEMBER, clientId), payload);

export const deleteClientRole = (memberId, clientId, role) => service.del(formatString(constants.CLIENT_ROLE, memberId, clientId, role));

export const deleteClientMembership = (memberId, clientId) => service.del(formatString(constants.CLIENT_MEMBERSHIP, memberId, clientId));

export const getOwners = () => service.get(constants.GET_MEMBER_OWNERS);

export const addOwner = (payload) => service.post(constants.OWNER, payload);

export const addOwnerMembership = (memberId, ownerId, roles) => service.post(formatString(constants.OWNER_MEMBERSHIP, memberId, ownerId), { roles });

export const addOwnerRole = (memberId, ownerId, role) => service.post(formatString(constants.OWNER_ROLE, memberId, ownerId, role));

export const deleteOwnerRole = (memberId, ownerId, role) => service.del(formatString(constants.OWNER_ROLE, memberId, ownerId, role));

export const deleteOwnerMembership = (memberId, ownerId) => service.del(formatString(constants.OWNER_MEMBERSHIP, memberId, ownerId));

export const addOwnerMembershipByUsername = (ownerId, payload) => service.post(formatString(constants.OWNER_MEMBER, ownerId), payload);

export const addMember = (payload) => service.post(constants.ADD_MEMBER, payload);

export const deleteMember = (id) => service.del(formatString(constants.MEMBER, id));

export const getMembersSearchList = (payload) => service.post(constants.MEMBERS_SEARCH, payload);

export const getPipelinesSearchList = (payload) => service.post(constants.PIPELINES_SEARCH, payload);

export const getPipelineDetails = (ownerId, pipelineId) => service.get(formatString(constants.TRANSFORMATION_PIPELINE, ownerId, pipelineId));

export const getInstancesSearchList = (clientId, payload) => service.post(formatString(constants.INSTANCES_SEARCH, clientId), payload);

export const getInstanceDetails = (clientId, instanceId) => service.get(formatString(constants.TRANSFORMATION_INSTANCE, clientId, instanceId));

export const runInstance = (clientId, instanceId, payload) => service.post(formatString(constants.RUN_INSTANCE, clientId, instanceId), payload);

export const editInstance = (clientId, instanceId, payload) => service.patch(formatString(constants.TRANSFORMATION_INSTANCE, clientId, instanceId), payload);

export const getTransformationExportTargets = () => service.get(formatString(constants.TRANSFORMATION_EXPORT_TARGETS));

export const getPipelines = () => service.get(constants.PIPELINES_LIST);

export const addInstance = (clientId, payload) => service.post(formatString(constants.ADD_TRANSFORMATION_INSTANCE, clientId), payload);

export const getInstances = (clientId) => service.get(formatString(constants.TRANSFORMATION_INSTANCES_LIST, clientId));

export const getFeeds = (clientId) => service.get(formatString(constants.FEEDS_LIST, clientId));

export const validateApSql = (apSqlQuery, apSqlQueryVariables = {}) => service.post(constants.VALIDATE_AP_SQL, { apSqlQuery, apSqlQueryVariables });

export const getMember = (memberId) => service.get(formatString(constants.MEMBER, memberId));

export const getInstancesByTriggerId = (clientId, payload) => service.post(formatString(constants.INSTANCES_BY_TRIGGER, clientId), payload);

export const deleteInstance = (clientId, instanceId) => service.del(formatString(constants.TRANSFORMATION_INSTANCE, clientId, instanceId));

export const deleteSource = (ownerId, sourceId) => service.del(formatString(constants.SOURCE, ownerId, sourceId));

export const archiveSource = (ownerId, sourceId) => service.post(formatString(constants.ARCHIVE_SOURCE, ownerId, sourceId));

export const getClientDetails = (clientId) => service.get(formatString(constants.CLIENT_BY_ID, clientId));

export const editClient = (clientId, payload) => service.patch(formatString(constants.CLIENT_BY_ID, clientId), payload);

export const deleteClient = (clientId) => service.del(formatString(constants.CLIENT_BY_ID, clientId));

export const deleteOwner = (ownerId) => service.del(formatString(constants.OWNER_BY_ID, ownerId));

export const getFeedRuns = (clientId, payload) => service.post(formatString(constants.FEED_RUNS_LIST, clientId), payload);

export const rerunFeeds = (clientId, payload) => service.post(formatString(constants.FEED_RUNS_RESTART, clientId), payload);

export const getDatasets = (clientId) => service.get(constants.GET_DATASETS + (clientId ? buildQueryString({ clientId }) : ''));

export const getDatasetMetadata = (clientId, datasetId) =>
    service.get(formatString(constants.GET_DATASET_METADATA, datasetId) + (clientId ? buildQueryString({ clientId }) : ''));

export const getDatasetTables = (clientId, datasetId) =>
    service.get(formatString(constants.GET_DATASETS_TABLES, datasetId) + (clientId ? buildQueryString({ clientId }) : ''));

export const getTableData = (clientId, datasetId, tableId) =>
    service.get(formatString(constants.GET_TABLE_METADATA, datasetId, tableId) + (clientId ? buildQueryString({ clientId }) : ''));

export const searchDataCatalog = (clientId, query) =>
    service.get(constants.DATA_CATALOG_SEARCH + buildQueryString({ query, ...(clientId && { clientId }) }));

export const addGroup = (payload) => service.post(constants.GROUP, payload);

export const getGroupDetails = (groupId) => service.get(formatString(constants.GROUP_BY_ID, groupId));

export const editGroup = (groupId, payload) => service.patch(formatString(constants.GROUP_BY_ID, groupId), payload);

export const deleteGroupMembership = (groupId, memberId) => service.del(formatString(constants.GROUP_MEMBERSHIP_BY_ID, groupId, memberId));

export const deleteGroup = (groupId) => service.del(formatString(constants.GROUP_BY_ID, groupId));

export const getGroupsSearchList = (payload) => service.post(constants.GROUPS_SEARCH, payload);

export const addGroupMemberships = (groupId, payload) => service.post(formatString(constants.GROUP_MEMBERSHIP, groupId), payload);

export const getGroupsList = () => service.get(constants.GROUP);

export const getOwnerDetails = (ownerId) => service.get(formatString(constants.OWNER_BY_ID, ownerId));

export const promoteTestSource = (ownerId, sourceId) => service.post(formatString(constants.PROMOTE_SOURCE, ownerId, sourceId));

export const getTargetCredentialManifest = (targetType, authMethod) => service.get(formatString(constants.TARGET_CREDENTIAL_MANIFEST, targetType, authMethod));

export const addTargetCredential = (ownerId, payload) => service.post(formatString(constants.TARGET_CREDENTIAL, ownerId), payload);

export const getTargetCredentialDetails = (ownerId, credentialId) => service.get(formatString(constants.TARGET_CREDENTIAL_BY_ID, ownerId, credentialId));

export const editTargetCredential = (ownerId, credentialId, payload) => service.patch(formatString(constants.TARGET_CREDENTIAL_BY_ID, ownerId, credentialId), payload);

export const getTargetCredentialsSearchList = (payload) => service.post(constants.TARGET_CREDENTIALS_SEARCH, payload);

export const getTargetsSearchList = (payload) => service.post(constants.TARGETS_SEARCH, payload);

export const getTargetDetails = (ownerId, targetId) => service.get(formatString(constants.TARGET_BY_ID, ownerId, targetId));

export const getTargetManifest = (targetType) => service.get(formatString(constants.TARGET_CONFIGURATION_MANIFEST, targetType));

export const addTarget = (ownerId, payload) => service.post(formatString(constants.TARGET, ownerId), payload);

export const editTarget = (ownerId, targetId, payload) => service.patch(formatString(constants.TARGET_BY_ID, ownerId, targetId), payload);

export const deleteTarget = (ownerId, targetId) => service.del(formatString(constants.TARGET_BY_ID, ownerId, targetId));

export const getTargetsList = () => service.get(constants.TARGETS_LIST);

export const getTargetCredentialsList = () => service.get(constants.TARGET_CREDENTIALS_LIST);

export const addIngestionSubscription = (payload) => service.post(constants.INGESTION_SUBSCRIPTION, payload);

export const deleteIngestionSubscription = (payload) => service.del(constants.INGESTION_SUBSCRIPTION, payload);

export const getIngestionSubscriptionsByReferenceEntityId = (entityId, eventTypeGroup) => service.get(formatString(constants.INGESTION_SUBSCRIPTIONS_BY_REFERENCE_ENTITY_ID, entityId, eventTypeGroup));

export const getUploads = (clientId, type, year, month) => service.get(formatString(constants.MANUAL_UPLOAD, clientId, type) + buildQueryString({ year, month }));

export const uploadFile = (clientId, type, file) => service.uploadFile(formatString(constants.MANUAL_UPLOAD, clientId, type), 'reportFile', file);

export const getReportsList = (clientId, payload) => service.post(formatString(constants.REPORTS_SEARCH, clientId), payload);

export const getReportDetails = (clientId, reportId) => service.get(formatString(constants.REPORT_BY_ID, clientId, reportId));

export const getReportTemplates = () => service.get(constants.REPORT_TEMPLATE);

export const getReportTemplateDetails = (templateId) => service.get(formatString(constants.REPORT_TEMPLATE_BY_ID, templateId));

export const getReportMarkets = () => service.get(constants.REPORT_MARKET);

export const addReport = (clientId, payload) => service.post(formatString(constants.REPORT, clientId), payload);

export const editReport = (clientId, reportId, payload) => service.patch(formatString(constants.REPORT_BY_ID, clientId, reportId), payload);

export const getReportCollectorCredentialsList = () => service.get(constants.REPORT_COLLECTOR_CREDENTIALS_LIST);

export const validateReport = (clientId, payload) => service.post(formatString(constants.VALIDATE_REPORT, clientId), payload);

export const deleteReport = (clientId, reportId) => service.del(formatString(constants.REPORT_BY_ID, clientId, reportId));

export const addReportCollectorCredential = (ownerId, payload) => service.post(formatString(constants.ADD_REPORT_COLLECTOR_CREDENTIAL, ownerId), payload);

export const rerunReport = (clientId, reportId) => service.post(formatString(constants.RUN_REPORT, clientId, reportId));

export const rerunReportPlatform = (clientId, reportId, platformId) => service.post(formatString(constants.RUN_REPORT_PLATFORM, clientId, reportId, platformId));

export const rerunReportInstance = (clientId, reportId, instanceId) => service.post(formatString(constants.RUN_REPORT_INSTANCE, clientId, reportId, instanceId));

export const getReportAssets = (clientId, reportId) => service.get(formatString(constants.REPORT_ASSETS, clientId, reportId));

export const getAdverityAuthorizationTypes = () => service.get(constants.ADVERITY_AUTHORIZATION_TYPES);

export const getAdverityAuthorizationTypeOptions = (authorizationTypeId) => service.get(formatString(constants.ADVERITY_AUTHORIZATION_TYPE_OPTIONS, authorizationTypeId));

export const addAdverityAuthorization = (clientId, authorizationTypeId, payload) => service.post(formatString(constants.ADVERITY_AUTHORIZATIONS, clientId, authorizationTypeId), payload);

export const getAdverityAuthorizationsByType = (clientId, authorizationTypeId, page, pageSize) => service.get(formatString(constants.ADVERITY_AUTHORIZATIONS, clientId, authorizationTypeId) + buildQueryString({ page, page_size: pageSize }));

export const deleteAdverityAuthorization = (clientId, authorizationTypeId, authorizationId) => service.del(formatString(constants.ADVERITY_AUTHORIZATION_BY_ID, clientId, authorizationTypeId, authorizationId));

export const authorizeAdverityAuthorization = (clientId, authorizationTypeId, authorizationId) => service.get(formatString(constants.AUTHORIZE_ADVERITY_AUTHORIZATION, clientId, authorizationTypeId, authorizationId));

export const getAdverityDatastreamTypes = () => service.get(constants.ADVERITY_DATASTREAM_TYPES);

export const getAdverityAuthorizationsByDatastreamType = (clientId, datastreamTypeId) => service.get(formatString(constants.ADVERITY_AUTHORIZATIONS_BY_DATASTREAM_TYPE, clientId, datastreamTypeId));

export const addReportingSubscription = (payload) => service.post(constants.REPORTING_SUBSCRIPTION, payload);

export const deleteReportingSubscription = (payload) => service.del(constants.REPORTING_SUBSCRIPTION, payload);

export const getReportingSubscriptionsByReferenceEntityId = (entityId) => service.get(formatString(constants.REPORTING_SUBSCRIPTIONS_BY_REFERENCE_ENTITY_ID, entityId));
